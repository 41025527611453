import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { paths } from 'src/routes/paths';

import { selectUser } from 'src/redux/auth/selectors';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  user: icon('ic_user'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  contact: icon('ic_menu_item'),
  godowns:icon('ic_tour'),
  branches:icon('ic_folder'),
  staff:icon('ic_lock'),
  purchase: icon('ic_ecommerce'),
  sales: icon('ic_external'),
  returns: icon('ic_internal'),
  payments:icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {

  let userPermissions = useSelector(selectUser);
  userPermissions = userPermissions.custom_permissions || [];

  const data = useMemo(
    () => {
      const modules = [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.root,
            permissions:['is_read_branch_dashboard'],
            icon: ICONS.dashboard,
          },

        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          // PRODUCT
          {
            title: 'products',
            path: paths.dashboard.product.root,
            icon:ICONS.product,
            children: [
              { title: 'list', path: paths.dashboard.product.root, permissions: ['is_read_products'] },
              { title: 'create', path: paths.dashboard.product.new, permissions: ['is_create_products'] },
              { title: 'analytics', path: paths.dashboard.product.analytics, permissions: ['is_read_products_analytics'] },
            ],
          },
          // CONTACT
          {
            title: 'contacts',
            path: paths.dashboard.contact.root,
            icon: ICONS.contact,
            children: [
              { title: 'list', path: paths.dashboard.contact.root, permissions: ['is_read_contacts'] },
              { title: 'create', path: paths.dashboard.contact.new, permissions: ['is_create_contacts'] },
            ],
          },
          // PURCHASES
          {
            title: 'purchases',
            path: paths.dashboard.purchase.root,
            icon: ICONS.purchase,
            children: [
              { title: 'list', path: paths.dashboard.purchase.root, permissions: ['is_read_purchases'] },
              { title: 'create', path: paths.dashboard.purchase.new, permissions: ['is_create_purchases'] },
              { title: 'analytics', path: paths.dashboard.purchase.analytics, permissions: ['is_read_purchases_analytics'] },
            ],
          },
          // SALES
          {
            title: 'sales',
            path: paths.dashboard.sales.root,
            icon: ICONS.sales,
            children: [
              { title: 'list', path: paths.dashboard.sales.root, permissions: ['is_read_sales'] },
              { title: 'create', path: paths.dashboard.sales.new, permissions: ['is_create_sales']},
              { title: 'analytics', path: paths.dashboard.sales.analytics, permissions: ['is_read_sales_analytics'] },
            ],
          },
          // SALES RETURNS
          {
            title: 'sales returns',
            path: paths.dashboard.returns.root,
            icon: ICONS.returns,
            children: [
              { title: 'list', path: paths.dashboard.returns.root, permissions: ['is_read_sales_returns'] },
              { title: 'create', path: paths.dashboard.returns.new, permissions: ['is_create_sales_returns'] },
              { title: 'analytics', path: paths.dashboard.returns.analytics, permissions: ['is_read_sales_returns_analytics'] },
            ],
          },
          // Payments
          {
            title: 'payments',
            path: paths.dashboard.payments.root,
            icon: ICONS.payments,
            children: [
              { title: 'list', path: paths.dashboard.payments.root, permissions: ['is_read_payments'] },
              { title: 'create', path: paths.dashboard.payments.new, permissions: ['is_create_payments'] },
              { title: 'analytics', path: paths.dashboard.payments.analytics, permissions: ['is_read_payments_analytics'] },
            ],
          },
          // GODOWN
          {
            title: 'godowns',
            path: paths.dashboard.godowns.root,
            icon: ICONS.godowns,
            children: [
              { title: 'list', path: paths.dashboard.godowns.root, permissions: ['is_read_godowns'] },
              { title: 'create', path: paths.dashboard.godowns.new, permissions: ['is_create_godowns']},
              { title: 'analytics', path: paths.dashboard.godowns.analytics,},
            ],
          },

          // BRANCH
          {
            title: 'branches',
            path: paths.dashboard.branches.root,
            icon: ICONS.branches,
            children: [
              { title: 'list', path: paths.dashboard.branches.root, permissions: ['is_clientadmin'] },
              { title: 'create', path: paths.dashboard.branches.new, permissions: ['is_clientadmin'] },
            ],
          },

          // sTAFF
          {
            title: 'Staff',
            path: paths.dashboard.staff.root,
            icon: ICONS.user,
            children: [
              { title: 'list', path: paths.dashboard.staff.root, permissions: ['is_branchadmin'] },
              { title: 'create', path: paths.dashboard.staff.new, permissions: ['is_branchadmin']},
            ],
          },

          // USER
          {
            title: 'Account & settings',
            path: paths.dashboard.user.account,
            icon: ICONS.user,
          },

        ],
      },
    ];

    const filteredModules = modules.map(module => {
      const filteredItems = module.items.reduce((acc, item) => {
        if (!item.permissions || item.permissions.some(permission => userPermissions.includes(permission))) {
          if (!item.children) {
            acc.push(item);
          } else {
            const filteredChildren = item.children.filter(child =>
              !child.permissions || child.permissions.some(permission =>
                userPermissions.includes(permission)
              )
            );
            if (filteredChildren.length > 0) {
              acc.push({ ...item, children: filteredChildren });
            }
          }
        }
        return acc;
      }, []);

      return {
        ...module,
        items: filteredItems,
      };
    }).filter(module => module.items.length > 0);

    return filteredModules;
  }, [userPermissions]);

  return data;
}
